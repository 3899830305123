// angular
import { MapsAPILoader } from '@agm/core'
import { ElementRef, Injectable } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
// rxjs
import { Subject } from 'rxjs'
// app
import { AppService } from '../../../../app.service'
import { DeviceInfo, ProficloudInputConfig } from '../proficloud.interfaces'
import { design } from './ui.design-data'

@Injectable()
export class UiService {
  public humansBase = '/assets/proficloud/images/humans/'

  dashboardScrollRequired$ = new Subject<boolean>()

  popupPositionRecalculationRequired$ = new Subject<boolean>()

  menuOpened$ = new Subject<ElementRef>()

  // ui data
  uiData = {
    key: 'uiData',
    isMobile: false,
    mobileBelow: 960,
    showMobileNav: false,
  }

  colors = {
    persianGreen: '#0098A1',
    blackHaze: '#EBECEC',
    whiteSmoke: '#F5F5F5',
    jetBlack: '#343434',
  }

  design = design

  routeTitle: string

  routeSubTitle: string

  routeServiceId: string

  routeExtra?: string

  geoCoder: google.maps.Geocoder

  superscriptCharacters: Record<number, string> = {
    0: '⁰',
    1: '¹',
    2: '²',
    3: '³',
    4: '⁴',
  }

  constructor(
    public app: AppService,
    // maps
    public mapsAPILoader: MapsAPILoader
  ) {}

  /**
   *      Functions
   */
  public isTouchScreen() {
    return navigator.userAgent.match(
      /Tablet|iPad|Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i
    )
  }

  public setupLocationSearch(context: any, elementId: string, callback: (input: google.maps.places.Autocomplete) => void) {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder()
      const locationInput = document.getElementById(elementId)

      const autocomplete = new google.maps.places.Autocomplete(locationInput as HTMLInputElement, {
        types: ['address'],
      })
      autocomplete.addListener('place_changed', () => {
        callback.call(context, autocomplete)
      })
    })
  }

  public formFieldsToObject(fieldsArray: ProficloudInputConfig[]) {
    const fieldsObject: Record<string, UntypedFormControl> = {}
    fieldsArray.forEach((field) => {
      fieldsObject[field.key] = field.control
    })
    return fieldsObject
  }

  public scrollToElement(device: DeviceInfo) {
    // Note: This is clearly brittle but not sure of a better way of doing it
    // given that we can't get hold of the particular device item component
    // reference from the map component.
    const elId = 'device-list-item-' + device.metadata.uuid
    const el = document.getElementById(elId)
    el?.scrollIntoView()
  }

  public getSelectOptionsForCountries() {
    const countries = [
      'Germany',
      'Spain',
      'Italy',
      'Switzerland',
      'United Kingdom',
      'United States of America',
      'Afghanistan',
      'Albania',
      'Algeria',
      'American Samoa',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antigua & Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia',
      'Bonaire',
      'Bosnia & Herzegovina',
      'Botswana',
      'Brazil',
      'British Indian Ocean Ter',
      'Brunei',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Canary Islands',
      'Cape Verde',
      'Cayman Islands',
      'Central African Republic',
      'Chad',
      'Channel Islands',
      'Chile',
      'China',
      'Christmas Island',
      'Cocos Island',
      'Colombia',
      'Comoros',
      'Congo',
      'Cook Islands',
      'Costa Rica',
      'Cote DIvoire',
      'Croatia',
      'Cuba',
      'Curacao',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'East Timor',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Ethiopia',
      'Falkland Islands',
      'Faroe Islands',
      'Fiji',
      'Finland',
      'France',
      'French Guiana',
      'French Polynesia',
      'French Southern Ter',
      'Gabon',
      'Gambia',
      'Georgia',
      'Ghana',
      'Gibraltar',
      'Great Britain',
      'Greece',
      'Greenland',
      'Grenada',
      'Guadeloupe',
      'Guam',
      'Guatemala',
      'Guinea',
      'Guyana',
      'Haiti',
      'Hawaii',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'Indonesia',
      'India',
      'Iran',
      'Iraq',
      'Ireland',
      'Isle of Man',
      'Israel',
      'Jamaica',
      'Japan',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Korea North',
      'Korea South',
      'Kuwait',
      'Kyrgyzstan',
      'Laos',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macau',
      'Macedonia',
      'Madagascar',
      'Malaysia',
      'Malawi',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Martinique',
      'Mauritania',
      'Mauritius',
      'Mayotte',
      'Mexico',
      'Midway Islands',
      'Moldova',
      'Monaco',
      'Mongolia',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Nambia',
      'Nauru',
      'Nepal',
      'Netherland Antilles',
      'Netherlands (Holland, Europe)',
      'Nevis',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Niue',
      'Norfolk Island',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau Island',
      'Palestine',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Pitcairn Island',
      'Poland',
      'Portugal',
      'Puerto Rico',
      'Qatar',
      'Republic of Montenegro',
      'Republic of Serbia',
      'Reunion',
      'Romania',
      'Russia',
      'Rwanda',
      'St Barthelemy',
      'St Eustatius',
      'St Helena',
      'St Kitts-Nevis',
      'St Lucia',
      'St Maarten',
      'St Pierre & Miquelon',
      'St Vincent & Grenadines',
      'Saipan',
      'Samoa',
      'Samoa American',
      'San Marino',
      'Sao Tome & Principe',
      'Saudi Arabia',
      'Senegal',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'Sri Lanka',
      'Sudan',
      'Suriname',
      'Swaziland',
      'Sweden',
      'Syria',
      'Tahiti',
      'Taiwan',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      'Togo',
      'Tokelau',
      'Tonga',
      'Trinidad & Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks & Caicos Is',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Vatican City State',
      'Venezuela',
      'Vietnam',
      'Virgin Islands (Brit)',
      'Virgin Islands (USA)',
      'Wake Island',
      'Wallis & Futana Is',
      'Yemen',
      'Zaire',
      'Zambia',
      'Zimbabwe',
    ]

    return countries.map((c) => {
      return { key: c.toLowerCase(), value: c }
    })
  }

  public openURL(url: string) {
    window.open(url)
  }

  public capitalise(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
}
