import { Injectable } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms'
import { Location, ProficloudInputConfig } from '../../../services/proficloud.interfaces'
@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  noSpecialCharactersValidator = Validators.pattern('^[^()<>&*‘|=?;[\\]^~!."%\\\\/:+,\'`´]+$')
  uuidValidator = Validators.pattern('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89ABab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$')
  location?: Location

  latlngPattern = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/i

  uuidField(placeholder: string): ProficloudInputConfig {
    return {
      type: 'text',
      placeholder: placeholder,
      helpText: placeholder,
      icon: 'fingerprint',
      value: '',
      control: new UntypedFormControl('', [Validators.required, this.uuidValidator]),
      key: 'uuid',
      inputId: 'add-device-uuid',
    }
  }
  nameField(placeholder: string, maxLength: number, allowSpecialCharacters: boolean = false): ProficloudInputConfig {
    let field: ProficloudInputConfig = {
      type: 'text',
      placeholder: placeholder,
      helpText: placeholder,
      icon: 'label',
      value: '',
      control: new UntypedFormControl('', [Validators.maxLength(maxLength), Validators.required]),
      key: 'deviceName',
      inputId: 'add-device-name',
    }
    if (!allowSpecialCharacters) {
      field.control.addValidators(this.noSpecialCharactersValidator)
    }
    return field
  }

  commentField(placeholder: string): ProficloudInputConfig {
    return {
      type: 'textArea',
      placeholder: placeholder,
      helpText: placeholder,
      icon: 'comment',
      value: '',
      control: new UntypedFormControl(),
      key: 'comment',
      inputId: 'add-device-comment',
    }
  }

  tagsField(placeholder: string, tagLengthLimit: number): ProficloudInputConfig {
    return {
      type: 'chips',
      placeholder: placeholder,
      helpText: placeholder,
      icon: 'comment',
      value: [], // Tags
      control: new UntypedFormControl('', {
        validators: [
          (control: UntypedFormControl) => {
            if (control.value !== null && control.value.length > tagLengthLimit) {
              return { tagTooLong: true }
            }
            return null
          },
        ],
      }),
      key: 'tags',
      inputId: 'add-device-tags',
    }
  }

  locationField(placeholder: string): ProficloudInputConfig {
    return {
      type: 'text',
      placeholder: placeholder,
      helpText: placeholder,
      icon: 'map',
      value: '',
      key: 'location',
      inputId: 'device-input-location',
      control: new UntypedFormControl('', {
        asyncValidators: [
          async (control: UntypedFormControl) => {
            // This is a time.sleep equivalent because this validation is triggered before autocomplete fetched the coordinates
            await new Promise((r) => setTimeout(r, 200))
            if (
              (this.location?.lat && this.location.lng && this.location.address == control.value) ||
              this.latlngPattern.test(control.value) ||
              !control.value
            ) {
              return null
            }
            return {
              invalidLocation: true,
            }
          },
        ],
        updateOn: 'change',
      }),
    }
  }

  locationChanged(value: string) {
    // Clear selected address or set latlng
    if (this.latlngPattern.test(value)) {
      this.location = {
        lat: value.split(',')[0].trim(),
        lng: value.split(',')[1].trim(),
      }
    }
    delete this.location
  }
}
