@if (form | inputIsActive: config) {
  @if (!revealable) {
    <mat-form-field
      appearance="fill"
      class="input-password"
      [class.input-password--fit-width]="variant === 'fit-width'"
      [class.input-password--small]="size === 'small'">
      <mat-label>{{ placeholder }}</mat-label>
      <input
        [type]="'password'"
        [attr.data-analytics]="analyticsID || 'password-text-field'"
        autocomplete="on"
        matInput
        [placeholder]="placeholder"
        [id]="inputID"
        (input)="inputFieldChanged($event)"
        [formControl]="control" />
      @if (control?.errors && control?.touched) {
        <app-icon class="input-password__icon" [class.input-password__icon--small]="size === 'small'" [color]="'var(--error-red)'" name="warning" matSuffix>
        </app-icon>
      }
      @if (!control?.errors && control?.touched) {
        <app-icon
          class="input-password__icon input-password__icon--reset"
          [class.input-password__icon--small]="size === 'small'"
          color="var(--primary)"
          name="cross"
          matSuffix
          (click)="resetInput()"></app-icon>
      }
    </mat-form-field>
  } @else {
    <!-- It is not possible to have multiple suffixes on a mat-form field. Therefore the error and clear icon cannot be added in the reveal mode -->
    <mat-form-field
      appearance="fill"
      class="input-password"
      [class.input-password--fit-width]="variant === 'fit-width'"
      [class.input-password--small]="size === 'small'">
      <mat-label>{{ placeholder }}</mat-label>
      <input
        [type]="hide ? 'password' : 'text'"
        matInput
        autocomplete="on"
        [placeholder]="placeholder"
        [id]="inputID"
        [attr.data-analytics]="analyticsID || 'password-text-field'"
        (input)="inputFieldChanged($event)"
        [formControl]="control" />
      @if (!control.errors) {
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" tabindex="-1">
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      }
    </mat-form-field>
  }
}
