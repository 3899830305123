@if (!isMulti(config) && (form | inputIsActive: config)) {
  <mat-form-field
    (click)="superficial ? $event.stopPropagation() : undefined"
    [class.input-select--standalone]="standAlone"
    [subscriptSizing]="standAlone ? 'dynamic' : 'fixed'"
    style="width: 100%">
    <mat-label>{{ config?.placeholder ?? 'placeholder' }}</mat-label>
    <mat-select
      [placeholder]="config?.placeholder ?? 'placeholder'"
      [formControl]="config?.control"
      (click)="superficial ? superficialTriggered?.emit($event) : undefined"
      (focus)="hasFocus = true"
      (blur)="hasFocus = false"
      (selectionChange)="selectionChanged($event)"
      [id]="config.inputId">
      @if (specialEntry) {
        <mat-option [value]="'special'"> -- {{ specialEntry }} -- </mat-option>
      }
      @for (item of visibleSelectOptions(config?.selectValues); track item) {
        <mat-option [value]="item.key">
          {{ item.value }}
        </mat-option>
      }
    </mat-select>
    @if (config.infoText) {
      <app-icon matSuffix name="infoInverted" color="var(--primary)" class="info-icon" [matTooltip]="config.infoText"></app-icon>
    }
  </mat-form-field>
}
