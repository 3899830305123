import { Component, inject } from '@angular/core'
import { Subject } from 'rxjs'
import { PcStatusOverlayService } from '../../services/pc-status-overlay/pc-status-overlay.service'

export interface PCStatusOverlayConfig {
  type: 'success' | 'error' | 'warning' | 'spinner'
  message: string
  title?: string
  closeable?: boolean
  primaryAction?: { text: string; verifiable: boolean; execute$: Subject<any> }
  secondaryAction?: { text: string; verifiable: boolean; execute$: Subject<any> }
  extraInfo?: string
  onCloseRoute?: string
  closeEvent$?: Subject<null>
  timeout?: number
  preventAutoClose?: boolean
  onCloseCallback?: () => void
}

@Component({
  selector: 'pc-status-overlay',
  templateUrl: './pc-status-overlay.component.html',
  styleUrls: ['./pc-status-overlay.component.scss'],
})
export class PCStatusOverlayComponent {
  public overlayService = inject(PcStatusOverlayService)

  triggerPrimaryAction($event: any) {
    this.overlayService.config?.primaryAction?.execute$.next($event)
  }

  triggerSecondaryAction($event: any) {
    this.overlayService.config?.secondaryAction?.execute$.next($event)
  }

  triggerCloseAction() {
    if (this.overlayService.config?.closeEvent$) {
      this.overlayService.config.closeEvent$.next(null)
    } else {
      this.overlayService.resetStatus()
    }
    if (this.overlayService.config?.onCloseCallback) {
      this.overlayService.config.onCloseCallback()
    }
  }
}
