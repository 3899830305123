@if (form | inputIsActive: config) {
  <mat-form-field
    class="input-chiplist input-chiplist--fit-width"
    data-analytics="edit-device-modal-tags"
    [class.input-chiplist--fit-width]="variant === 'fit-width'">
    <mat-label data-analytics="edit-device-modal-tags-label">{{ chipsField.placeholder ? (chipsField.placeholder | translate) : '' }}</mat-label>
    <mat-chip-grid #chipGrid aria-label="{{ chipsField.placeholder ? (chipsField.placeholder | translate) : '' }}" data-analytics="edit-device-modal-tags">
      @for (tag of chipsField.value; track tag) {
        <mat-chip-row (removed)="removeTag(tag)" data-analytics="edit-device-modal-tag">
          {{ tag }}
          <button matChipRemove [attr.aria-label]="'remove ' + tag" data-analytics="edit-device-modal-tag-remove-button">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
      <input
        matInput
        [formControl]="chipsField.control"
        [placeholder]="chipsField.placeholder ? (chipsField.placeholder | translate) : ''"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addTag($event.value)"
        data-analytics="edit-device-modal-tags-input" />
    </mat-chip-grid>
  </mat-form-field>
}
