import { Component, Input } from '@angular/core'

export interface IPCTableItem {
  tableItemId: string
  tableItemValue: string
}

@Component({
  selector: 'pc-table',
  templateUrl: './pc-table.component.html',
  styleUrls: ['./pc-table.component.scss'],
})
export class PCTableComponent {
  @Input() titleFirstCol = 'Title First Column'

  @Input() titleSecondCol = 'Title Second Column'

  @Input() items: IPCTableItem[] = []

  @Input() loading = false

  isAscending = false

  sort() {
    this.items = this.items.sort((a, b) => {
      if (a.tableItemId > b.tableItemId) return this.isAscending ? 1 : -1
      if (a.tableItemId < b.tableItemId) return this.isAscending ? -1 : 1
      return 0
    })
    this.isAscending = !this.isAscending // Toggle the sorting order
  }
}
