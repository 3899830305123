@if (form | inputIsActive: config) {
  <mat-form-field
    [subscriptSizing]="standAlone ? 'dynamic' : 'fixed'"
    class="input-textfield"
    [class.input-textfield--fit-width]="variant === 'fit-width'"
    [class.input-textfield--small]="size === 'small'">
    <mat-label>{{ placeholder }}</mat-label>
    @if (initialFocus) {
      <input
        [type]="numbersOnly ? 'number' : 'text'"
        autofocus
        matInput
        [placeholder]="placeholder"
        [id]="inputID"
        (input)="inputFieldChanged($event)"
        [formControl]="control" />
    }
    @if (!initialFocus) {
      <input
        matInput
        [placeholder]="placeholder"
        [type]="numbersOnly ? 'number' : 'text'"
        [id]="inputID"
        (input)="inputFieldChanged($event)"
        [formControl]="control"
        [readonly]="disabled" />
    }
    @if (hint) {
      <mat-hint>{{ hint }}</mat-hint>
    }
    @if (control?.errors && control?.touched) {
      <app-icon class="input-textfield__icon" [class.input-textfield__icon--small]="size === 'small'" [color]="'var(--error-red)'" name="warning" matSuffix>
      </app-icon>
    }
    @if (errorHint) {
      <mat-error>{{ errorHint }} </mat-error>
    }

    @if (!control?.errors && control?.touched) {
      <app-icon
        class="input-textfield__icon input-textfield__icon--reset"
        [class.input-textfield__icon--small]="size === 'small'"
        color="var(--primary)"
        name="cross"
        matSuffix
        (click)="resetInput()"></app-icon>
    }
  </mat-form-field>
}
