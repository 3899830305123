// angular
import { Injectable } from '@angular/core'
import { DefaultLangChangeEvent, TranslateService } from '@ngx-translate/core'
import { Entry, createClient } from 'contentful'
import { BehaviorSubject } from 'rxjs'
import { AuthorisationService } from '../authorisation.service'
import { getAppName } from '../proficloud.service'
import {
  ContentfulService,
  ContractPartner,
  CountryTerms,
  Maintenence,
  Permission,
  PublicServiceStore,
  ServicePermissions,
} from './../billing/contentful-interfaces'

interface Content {
  advantages?: any[]
  landingPage?: any
  services?: ContentfulService[]
  countryTerms?: CountryTerms[]
  contractPartners?: ContractPartner[]
  maintenance?: Maintenence
  publicServiceStore?: PublicServiceStore
  servicePermissions?: ServicePermissions[]
}

@Injectable()
export class ContentService {
  // streams
  servicesLoaded$ = new BehaviorSubject<boolean>(false)

  countriesLoaded$ = new BehaviorSubject<boolean>(false)

  contentfulConfig = {
    space: 'bqwo92p1eklz',
    accessToken: 'b9DGEbk3YXqGTN8r1DfywC6XqMj-4XfyeLYJUlGcDtI',

    contentTypes: {
      landingPage: 'landingpage',
      advantages: {
        product: 'landingPageAdvantages',
      },
      services: 'serviceStore',
      countryTerms: 'downloads',
      maintenance: 'maintenance',
      publicStore: 'publicServiceStore',
      contractPartner: 'contractPartner',
      servicePermissions: 'permissionSmartServices',
      wallboxes: 'wallboxes',
      chargeRepayVideos: 'chargeRepayVideos',
    },
  }

  content: Content = {}

  timeSeriesDataService?: ContentfulService

  timeSeriesDataPrologisService?: ContentfulService

  impulseAnalyticsService?: ContentfulService

  emmaService?: ContentfulService

  dmsBasicAddOnService?: ContentfulService

  private cdaClient = createClient({
    space: this.contentfulConfig.space,
    accessToken: this.contentfulConfig.accessToken,
  })

  constructor(
    private translate: TranslateService,
    private authorization: AuthorisationService
  ) {
    /**
     *    only load content for proficloud
     */
    const appName = getAppName()
    if (appName !== 'proficloud') {
      return
    }

    translate.onDefaultLangChange.subscribe((event: DefaultLangChangeEvent) => {
      this.loadContent(event.lang)
    })
  }

  private loadContent(lang: string) {
    lang = Array.isArray(lang) ? lang[0] : lang

    this.getContractPartners(lang).then((partners) => {
      this.content.contractPartners = partners as unknown as ContractPartner[]
      this.countriesLoaded$.next(true)
    })
    this.getAdvantages(lang).then((advantages) => {
      this.content.advantages = advantages
    })
    this.getLandingPage(lang).then((lp) => {
      this.content.landingPage = lp
    })
    this.getPublicStore(lang).then((store) => {
      this.content.publicServiceStore = store
    })
    this.getServices(lang).then((r: any) => {
      this.content.services = r

      // set tsd
      this.timeSeriesDataService = this.content.services?.find((service) => service.fields.id === 'tsd')
      this.impulseAnalyticsService = this.content.services?.find((service) => service.fields.id === 'ia')
      this.emmaService = this.content.services?.find((service) => service.fields.id === 'emma')
      this.timeSeriesDataPrologisService = this.content.services?.find((service) => service.fields.id === 'tsd-prologis')
      this.dmsBasicAddOnService = this.content.services?.find((service) => service.fields.id === 'dmsAddonBasic')
      this.servicesLoaded$.next(true)
    })
    this.getCountryTerms(lang).then((r: any) => {
      this.content.countryTerms = r
    })
    this.getMaintenance(lang).then((r: any) => {
      const entry = r[0]
      if (!entry) {
        return
      }
      this.content.maintenance = entry
    })
    this.getRolesAndPermissions(lang).then((r: any) => {
      this.content.servicePermissions = r.map((smartServicePermission: any) => {
        // Get service name
        const serviceName = smartServicePermission.fields.smartServicePermissions
        // Map through permissions and create Permission object
        const servicePermissions: Permission[] = smartServicePermission.fields.permissions.map((servicePermission: any) => {
          // Map through allowed roles to get all roles which have this permission assigned
          const allowedRoles = servicePermission.fields.rolesAllowed?.map((role: string) => {
            return this.authorization.getOrganisationRole(role.toLowerCase())
          })
          return {
            name: servicePermission.fields.permissionName,
            description: servicePermission.fields.permissionDescription,
            allowed: allowedRoles,
          } as Permission
        })

        // return Service permission object based on above logic
        return { service: serviceName, permissions: servicePermissions } as ServicePermissions
      })
    })
  }

  async getPublicStore(lang: string, query?: object) {
    const xxx = Object.assign({
      content_type: this.contentfulConfig.contentTypes.publicStore,
      locale: lang,
    })

    const res = await this.cdaClient.getEntries(
      Object.assign(
        {
          content_type: this.contentfulConfig.contentTypes.publicStore,
          locale: lang,
        },
        query
      )
    )

    const publicServiceStore: PublicServiceStore = {
      fields: {
        headlineH1: res.items[0].fields.headlineH1 as string,
        headlineH2: res.items[0].fields.headlineH2 as string,
        introduction: res.items[0].fields.introduction as string,
        seoText: res.items[0].fields.seoText as string,
      },
    }

    return publicServiceStore
  }

  getLandingPage(lang: string, query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: this.contentfulConfig.contentTypes.landingPage,
            locale: lang,
          },
          query
        )
      )
      .then((res) => res.items)
  }

  // TODO: This does not seem to work because the advantages object is a object instead of a string which is not supported by contentful api
  async getAdvantages(lang: string, query?: object) {
    try {
      let res = await this.cdaClient.getEntries(
        Object.assign(
          {
            content_type: this.contentfulConfig.contentTypes.advantages.product,
            locale: lang,
          },
          query
        )
      )
      return res.items
    } catch (e) {
      console.error(e)
    }
  }

  getServices(lang: string, query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: this.contentfulConfig.contentTypes.services,
            order: 'fields.orderOption',
            locale: lang,
          },
          query
        )
      )
      .then((res) => res.items)
  }

  getCountryTerms(lang: string, query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: this.contentfulConfig.contentTypes.countryTerms,
            locale: lang,
          },
          query
        )
      )
      .then((res) => res.items)
  }

  getContractPartners(lang: string, query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: this.contentfulConfig.contentTypes.contractPartner,
            locale: lang,
          },
          query
        )
      )
      .then((res) => res.items)
  }

  getMaintenance(lang: string, query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: this.contentfulConfig.contentTypes.maintenance,
            locale: lang,
          },
          query
        )
      )
      .then((res) => res.items)
  }

  getRolesAndPermissions(lang: string, query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: this.contentfulConfig.contentTypes.servicePermissions,
            locale: lang,
          },
          query
        )
      )
      .then((res) => res.items)
  }

  getWallboxes(lang: string = 'en-GB', query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: this.contentfulConfig.contentTypes.wallboxes,
            locale: lang,
          },
          query
        )
      )
      .then((res) => res.items)
  }

  getVideos(lang: string = 'en-GB', query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: this.contentfulConfig.contentTypes.wallboxes,
            locale: lang,
          },
          query
        )
      )
      .then((res) => res.items)
  }
}
