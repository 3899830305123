import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ProficloudInputConfig } from '@services/proficloud.interfaces'
import { ValidationService } from 'src/app/modules/proficloud/modules/device-management/services/validation.service'
import { PcInputBaseComponent } from '../../base-classes/pc-input-base.component'

@Component({
  selector: 'pc-input-chiplist',
  templateUrl: './pc-input-chiplist.component.html',
  styleUrls: ['./pc-input-chiplist.component.scss'],
})
export class PCInputChiplistComponent extends PcInputBaseComponent {
  constructor(public validation: ValidationService) {
    super()
  }
  @Output() valueChanged: EventEmitter<string[]> = new EventEmitter()

  @Input() size: 'regular' | 'small' = 'regular'

  @Input() variant: 'fit-width'

  @Input() control: UntypedFormControl

  @Input({ required: true }) placeholder: string

  @Input() label: string

  @Input() errorHint: string

  @Input() search: boolean

  @Input() inputID: string

  @Input() inputValue: string[] = []

  touched: boolean
  chipsField: ProficloudInputConfig
  tagLengthLimit = 42
  separatorKeysCodes: number[] = [ENTER, COMMA]
  ngOnInit() {
    this.chipsField = this.validation.tagsField('DEVICE.NEW_TAG', this.tagLengthLimit)
    this.chipsField.value = this.inputValue
  }
  public resetInput() {
    this.control.setValue('')
  }

  public addTag(value: string) {
    if (value.length > this.tagLengthLimit) {
      return
    }
    this.chipsField.value = this.chipsField.value as string[]

    if (value.trim().length > 0 && !this.chipsField.value.includes(value.trim())) {
      this.chipsField.value.push(value.trim())
    }
    this.chipsField.control.setValue('')
  }
  public removeTag(tag: string) {
    const index = this.inputValue.indexOf(tag)
    this.inputValue.splice(index, 1)
  }

  // https://proficloud.atlassian.net/browse/DMS-568
  public onChipBlur(event: FocusEvent) {
    const value = (event.target as HTMLInputElement).value
    if (value.length > 0) {
      this.addTag(value)
    }
  }
}
