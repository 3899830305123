import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'middleTruncate',
})
export class MiddleTruncatePipe implements PipeTransform {
  transform(value: string, args: any[]) {
    if (value) {
      const limit = args.length > 0 ? parseInt(args[0], 10) : 20
      const trail = args.length > 1 ? args[1] : '...'
      const length = value.length

      const extra = length - limit
      const partLength = length / 2 - extra / 2
      let truncated_value = value.substring(0, partLength) + trail + value.slice(-partLength)

      // just return value, if adding middle truncate value does not make sence,
      // else truncated_value
      return value.length <= truncated_value.length ? value : truncated_value
    }
  }
}
