import { EnvironmentInterface } from '@environments/environment.interface'

export const environment: EnvironmentInterface = {
  name: 'chargerepay',
  sentryEnv: 'CHARGEREPAY',
  serviceBlacklist: [],
  production: true,
  gateway: false,
  version: 1633,
  chinaMode: false,
  chargeRepay: true,
  contactEmail: 'inbox@phoenixcontact-sb.io',
  copyright: '2024 © Proficloud. All rights reserved',
  dataPrivacyLink: 'https://proficloud.io/data-privacy',
  siteNoticeLink: 'https://proficloud.io/site-notice',
  softwareLicenceTerm: 'https://proficloud.io/software-license-terms-international',
  termsAndCondition: 'https://proficloud.io/info/terms-and-conditions',
  productDescription: 'https://proficloud.io/info/product-description',
  chargeRepayBaseURL: 'https://env.kaa.proficloud-production.io/emobility',
  chargeRepayInstallMgrURL: 'https://env.kaa.proficloud-production.io/charge-repay-installation-manager',
}
