import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'maskString',
})
export class MaskStringPipe implements PipeTransform {
  transform(value: string, visibleChars: number): string {
    if (!value || visibleChars >= value.length) {
      return value
    }
    const visiblePart = value.slice(0, visibleChars)
    const maskedPart = 'X'.repeat(value.length - visibleChars)
    return visiblePart + maskedPart
  }
}
