import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { DropDownItem } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'

@Component({
  selector: 'pc-button-dropdown',
  templateUrl: './pc-button-dropdown.component.html',
  styleUrls: ['./pc-button-dropdown.component.scss'],
})
export class PcButtonDropdownComponent implements OnInit {
  @Input()
  dropdownOptions: DropDownItem[]

  @Input()
  initialSelectedValue: string

  @Input()
  placeholder: string

  @Input()
  control: FormControl

  @Input()
  inputID: string

  @Input()
  size: 'regular' | 'minimal' = 'regular'

  @Input()
  wide: boolean = false

  @Input()
  characterLimit: number

  @Input()
  wideRight: boolean

  @Input()
  disabled: boolean = false

  @Input()
  scrollable: boolean = false

  @Input()
  limitedHeight: boolean = false

  @Input()
  afterClickedOutside: () => void

  @Output()
  onItemClicked: EventEmitter<any> = new EventEmitter()

  @Output()
  afterExpansionClicked: EventEmitter<any> = new EventEmitter()

  optionsVisible = false

  selectedItem?: DropDownItem

  constructor(public proficloud: ProficloudService) {}

  ngOnInit(): void {
    if (this.initialSelectedValue) {
      this.selectedItem = this.findItemOrSubItem(this.initialSelectedValue)
      if (!this.selectedItem) {
        console.warn('Initial selected value not found', this.initialSelectedValue, this.dropdownOptions)
      }
    }
  }

  private findItemOrSubItem(id: string) {
    let item = this.dropdownOptions.find((option) => option.id === id)
    if (!item) {
      const withSubItems = this.dropdownOptions.filter((option) => option.subItems?.length)
      withSubItems.forEach((si) => {
        const matchingSubItem = si.subItems?.find((i) => i.id === id)
        if (matchingSubItem) {
          item = matchingSubItem
        }
      })
    }
    return item
  }

  public onSelect(item: DropDownItem) {
    if (item && item.id) {
      this.selectedItem = this.findItemOrSubItem(item.id)
      this.optionsVisible = false
      this.control?.setValue(item.id)
      this.onItemClicked.emit(item)
    } else {
      console.error('No item was provided for the selection')
    }
  }

  public clickedOutside() {
    this.optionsVisible = false
    if (this.afterClickedOutside) {
      this.afterClickedOutside()
    }
  }

  public selectedValueChanged(value: string) {
    this.selectedItem = this.dropdownOptions.find((option) => option.id === value)
  }

  public toggleExpansion() {
    if (!this.disabled) {
      this.optionsVisible = !this.optionsVisible
    }
    if (this.afterExpansionClicked) {
      this.afterExpansionClicked.emit()
    }
  }
}
