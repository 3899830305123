import { Component } from '@angular/core'
import { environment } from '@environments/environment'
import { EnvironmentInterface } from '@environments/environment.interface'

@Component({
  selector: 'app-themed-proficloud-logo',
  templateUrl: './themed-proficloud-logo.component.html',
  styleUrl: './themed-proficloud-logo.component.scss',
})
export class ThemedProficloudLogoComponent {
  public env = (environment as EnvironmentInterface).name === 'metrowest' ? 'metrowest' : 'default'
}
