@if (!options?.dependsOn || (options?.dependsOn && form.controls[this.options?.dependsOn].valid)) {
  <flex-col
    #selectContainer
    class="tagItem"
    [id]="options.elementID"
    [ngClass]="[hasFocus ? 'valid' : '', 'proficloud-select', options.cssClass ? options.cssClass : '']"
    (clickOutside)="clickedOutside()">
    <flex-row-grow>
      @if (options.showValue) {
        <flex-row (click)="expand(false, $event)" id="proficloud-select-value" class="selectable">
          @for (value of selectedValue; track value) {
            <flex-row [class]="options.multi ? 'tag' : ''">
              <div style="padding-top: 3px" [title]="value.value">{{ value.value | middleTruncate: [20] }}</div>
              <div>
                @if (options.multi) {
                  <app-icon
                    [name]="'cross'"
                    color="var(--primary)"
                    class="icon remove-selection"
                    style="width: 20px; cursor: pointer; display: inline-block"
                    (click)="remove(value, $event)"></app-icon>
                }
              </div>
            </flex-row>
          }
          @if (
            (options.search && options.multi) ||
            (options.search && !options.multi && !selectedValue.length) ||
            (options.search && !options.multi && selectedValue.length && expanded)
          ) {
            <span (click)="expand(true, $event)">
              <input
                #filterInput
                [formControl]="filterControl"
                (focus)="hasFocus = true"
                (blur)="hasFocus = false"
                class="tagInput"
                (input)="updateFilterCriteria($event.target.value)"
                name="filterInput"
                id="filter-input"
                [placeholder]="options.placeholder || 'filter'"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false" />
            </span>
          }
          @if (!options.search && options.placeholder && !options.control.value && !selectedValue.length) {
            <span class="placeholder" style="padding-top: 3px">
              {{ options.placeholder }}
            </span>
          }
        </flex-row>
      }
      @if (!options.showValue) {
        <div id="profi-select-placeholder" (click)="expand(true, $event)" class="selectable">
          {{ options.placeholder }}
        </div>
      }
      <div style="min-width: 24px" class="profiSelectToggle" style="padding-top: 3px">
        <app-icon
          name="{{ expanded ? 'arrowDropUp' : 'arrowDropDown' }}"
          style="width: 24px; cursor: pointer"
          color="var(--primary)"
          (click)="expand(false, $event)"></app-icon>
      </div>
    </flex-row-grow>
    <div #container>
      <!-- Grouped -->
      @if (expanded && hasGroups) {
        <div class="content" [style.top]="contentTop" [style.left]="contentLeft" [style.minWidth]="contentWidth" [style.maxWidth]="contentWidth">
          <div class="inner">
            @for (item of optionsAsGroup() | keyvalue; track item) {
              <flex-col>
                @if (hasVisibleSubItems(item.value)) {
                  <flex-row class="item" (click)="groupItemClicked(item.value); $event.stopPropagation()">
                    @if (options.multi) {
                      <div class="icon-container">
                        @if (groupItemStatus(item.value) === 1) {
                          <app-icon [name]="'remove'" [color]="'#FFFFFF'" class="check"> </app-icon>
                        }
                        @if (groupItemStatus(item.value) === 2) {
                          <app-icon [name]="'check'" [color]="'#FFFFFF'" class="check"> </app-icon>
                        }
                      </div>
                    }
                    <span class="group-header">{{ item.value.label }}</span>
                  </flex-row>
                }
                @for (subItem of item.value.values | filter: ['isTrue', 'visible'] : ['isFalse', 'excluded']; track subItem) {
                  <flex-row class="item" (click)="subItemClicked(subItem); $event.stopPropagation()">
                    <flex-row class="level-1 sub-item">
                      @if (options.multi) {
                        <div class="icon-container">
                          @if (subItem.checked) {
                            <app-icon [name]="'check'" [color]="'#FFFFFF'" class="check"> </app-icon>
                          }
                        </div>
                      }
                      <app-highlighted-text [text]="subItem.value" [highlighted]="filterCriteria"></app-highlighted-text>
                      @if (subItem.item && subItem.item[subItem.tagProperty]) {
                        <div class="item-tag">
                          {{ subItem.item[subItem.tagProperty] }}
                        </div>
                      }
                    </flex-row>
                  </flex-row>
                }
              </flex-col>
            }
          </div>
          @if (options.multi) {
            <div class="done">
              <pc-button
                [disabled]="!selectedValue.length"
                size="small"
                [wide]="true"
                [text]="'SHARED.DONE' | translate"
                (btnClick)="clickedOutside()"
                inputID="select-done"></pc-button>
            </div>
          }
        </div>
      }
      <!-- Not grouped (just a flat list) -->
      @if (expanded && !hasGroups) {
        <flex-col class="content" [style.top]="contentTop" [style.left]="contentLeft" [style.minWidth]="contentWidth" [style.maxWidth]="contentWidth">
          <div class="inner">
            @for (item of itemsAsArray() | filter: ['isTrue', 'visible'] : ['isFalse', 'excluded']; track item) {
              <div class="item">
                <flex-row>
                  @if (options.multi) {
                    <div class="icon-container">
                      @if (item.checked) {
                        <app-icon [name]="'check'" [color]="'#FFFFFF'" class="check"> </app-icon>
                      }
                    </div>
                  }
                  <app-highlighted-text
                    (click)="subItemClicked(item); $event.stopPropagation()"
                    style="flex-grow: 1"
                    [text]="item.value"
                    [highlighted]="filterCriteria"></app-highlighted-text>
                  @if (options.showInfoText && item.infoText) {
                    <app-icon name="infoInverted" color="var(--primary)" class="info-icon" [matTooltip]="item.infoText"></app-icon>
                  }
                </flex-row>
              </div>
            }
          </div>
        </flex-col>
      }
    </div>
  </flex-col>
}
