import { Injectable } from '@angular/core'
import { CreateDevicePostData, Device, DeviceInfo, DeviceMetaData } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { BehaviorSubject, Observable, Subscription, forkJoin } from 'rxjs'
import { DeviceManagementHttpService } from '../backend/device-management-http.service'
import { DeviceGroup, DeviceGroupDevice } from '../services/device-group.interfaces'

@Injectable({
  providedIn: 'root',
})
export class DeviceStore {
  private organisationSwitchBegunSubscription: Subscription
  private organisationsListedSubscription: Subscription
  private organisationSwitchedSubscription: Subscription

  private _devices$: BehaviorSubject<DeviceInfo[]> = new BehaviorSubject([])
  public readonly devices$: Observable<DeviceInfo[]> = this._devices$.asObservable()

  private _deviceGroups$: BehaviorSubject<DeviceGroup[]> = new BehaviorSubject([])
  public readonly deviceGroups$: Observable<DeviceGroup[]> = this._deviceGroups$.asObservable()

  public storeInitialised$ = new BehaviorSubject<boolean>(true)
  private devicesInitialised = false

  constructor(
    private proficloud: ProficloudService,
    private deviceManagementHttp: DeviceManagementHttpService
  ) {
    this.organisationSwitchBegunSubscription = this.proficloud.organisationSwitchBegun$.subscribe(() => {
      // TODO
    })

    this.organisationsListedSubscription = this.proficloud.organisationsListed$.subscribe(() => {
      if (!this.devicesInitialised) {
        this.loadDevices(this.proficloud.currentOrganisation.organizationId)
        this.loadDeviceGroups()
      }
    })

    this.organisationSwitchedSubscription = this.proficloud.organisationSwitched$.subscribe((organisation) => {
      // Maybe
      this.loadDevices(organisation.organizationId)
      this.loadDeviceGroups()
    })
  }

  public refreshDevices(organisationId: string) {
    this.getOrganisationDevices(organisationId).subscribe((res) => {
      this._devices$.next(res.content)
    })
  }

  private loadDevices(organisationId: string) {
    this.getOrganisationDevices(organisationId).subscribe((res) => {
      this._devices$.next(res.content)
      this.devicesInitialised = true
      this.storeInitialised$.next(false)
    })
  }

  private wrapSuccessWithDevicesRefresh<T>(apiCall$: Observable<T>) {
    return new Observable<T>((res) => {
      apiCall$.subscribe({
        next: (response) => {
          this.loadDevices(this.proficloud.currentOrganisation.organizationId)
          res.next(response)
        },
        error: (err) => {
          res.error(err)
        },
      })
    })
  }
  private wrapSuccessWithDeviceGroupsRefresh<T>(apiCall$: Observable<T>) {
    return new Observable<T>((res) => {
      apiCall$.subscribe({
        next: (response) => {
          this.loadDeviceGroups()
          res.next(response)
        },
        error: (err) => {
          res.error(err)
        },
      })
    })
  }

  private getOrganisationDevices(organisationId: string) {
    return this.deviceManagementHttp.getOrganisationDevices(organisationId)
  }

  // Note: We might want to fetch this and then push it down a stream but I'm not sure if that makes sense given that it could be for multiple different devices
  public getDeviceLogs(fromDate: Date, toDate: Date, device: DeviceInfo) {
    return this.deviceManagementHttp.fetchDeviceLogs(fromDate, toDate, device)
  }

  public addDeviceLogEntry(device: DeviceInfo, entry: { message: string } = { message: 'Missing message' }) {
    return this.deviceManagementHttp.addDeviceLogEntry(device, entry)
  }

  public createDevice(createData: CreateDevicePostData, virtual: boolean) {
    return this.wrapSuccessWithDevicesRefresh(this.deviceManagementHttp.createDevice(createData, virtual))
  }

  public deleteDevice(device: Device) {
    return this.wrapSuccessWithDevicesRefresh(this.deviceManagementHttp.deleteDevice(device))
  }

  public updateDeviceDetail(endpointId: string, deviceFields: DeviceMetaData) {
    return this.wrapSuccessWithDevicesRefresh(this.deviceManagementHttp.updateDeviceDetail(endpointId, deviceFields))
  }

  public getAllDevicesMetaData() {
    return this.deviceManagementHttp.getAllDevicesMetaData()
  }

  public getDeviceServicesInfo() {
    return this.deviceManagementHttp.getDeviceServicesInfo()
  }

  public getDeviceTypeInfo() {
    return this.deviceManagementHttp.getDeviceTypeInfo()
  }

  public getLastDeviceHealthStatus(endpointIds: string, metricName: string) {
    return this.deviceManagementHttp.getLastDeviceHealthStatus(endpointIds, metricName)
  }

  public getDeviceGroups() {
    return this.deviceManagementHttp.loadDeviceGroups()
  }

  public loadDeviceGroups() {
    this.getDeviceGroups().subscribe({
      next: (response) => {
        this._deviceGroups$.next(response.data)
      },
      error: (error) => {
        this._deviceGroups$.next([])
      },
    })
  }

  public addEmptyDeviceGroup(name: string, description: string, tags: string[]) {
    return this.wrapSuccessWithDeviceGroupsRefresh(this.deviceManagementHttp.addEmptyDeviceGroup(name, description, tags))
  }

  public addDeviceToGroup(group: DeviceGroup, endpointId: string, x: number, y: number) {
    return this.wrapSuccessWithDeviceGroupsRefresh(this.deviceManagementHttp.addDeviceToGroup(group, endpointId, x, y))
  }

  public deleteDeviceFromGroup(group: DeviceGroup, endpointId: string) {
    return this.wrapSuccessWithDeviceGroupsRefresh(this.deviceManagementHttp.deleteDeviceFromGroup(group, endpointId))
  }

  public editDeviceGroup(group: DeviceGroup, name: string, description: string, tags: string[]) {
    return this.wrapSuccessWithDeviceGroupsRefresh(this.deviceManagementHttp.editDeviceGroup(group, name, description, tags))
  }

  public deleteDeviceGroup(group: DeviceGroup) {
    return this.wrapSuccessWithDeviceGroupsRefresh(this.deviceManagementHttp.deleteDeviceGroup(group))
  }

  public deleteImageGroup(group: DeviceGroup) {
    return this.deviceManagementHttp.deleteImageGroup(group)
  }

  public updateDevices(group: DeviceGroup) {
    return forkJoin(
      group.devices.map((device) => {
        return this.addDeviceToGroup(group, device.endpoint_id, device.location.x, device.location.y)
      })
    )
  }

  public deleteGroupImage(group: DeviceGroup) {
    // reset all device positions
    group.devices.forEach((device) => {
      device.location.x = 0
      device.location.y = 0
    })
    this.updateDevices(group)

    // remove image from group
    return this.deleteImageGroup(group)
  }

  public uploadGroupImage(group: DeviceGroup, event: { target: { files: File[] } }) {
    return this.wrapSuccessWithDeviceGroupsRefresh(this.deviceManagementHttp.uploadGroupImage(group, event))
  }

  public loadGroupImage(group: DeviceGroup) {
    return this.deviceManagementHttp.loadGroupImage(group)
  }

  public placeGroupDevice(group: DeviceGroup, device: DeviceGroupDevice, x: number, y: number) {
    return this.deviceManagementHttp.placeGroupDevice(group, device, x, y).subscribe(
      // Do nothing
      (response) => {}
    )
  }
}
