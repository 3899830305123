<div class="pc-overlay">
  <!-- top spacer -->
  <div class="pc-overlay__top-spacer"></div>

  <div
    class="pc-overlay__window"
    [@scaleIn]
    [@fadeIn]
    [@.disabled]="disableAnimations"
    [class.pc-overlay__window--small]="small"
    [class.pc-overlay__window--large]="large"
    [class.pc-overlay__window--full-width]="fullWidth"
    data-analytics="modal">
    <div class="pc-overlay__content">
      <div class="pc-overlay__header">
        <div class="pc-overlay__title" data-analytics="modal headline">
          {{ config.title | translate }}
        </div>
        @if (config.closeable) {
          <app-icon
            data-analytics="close"
            class="pc-overlay__close-icon"
            id="modal-close"
            color="var(--jet-black)"
            name="cross"
            (click)="closeClick.next(); $event.stopPropagation()"></app-icon>
        }
      </div>
      <div class="pc-overlay__body" data-analytics="modal detail">
        <ng-content select="[body]"></ng-content>
      </div>
      <div class="pc-overlay__footer" data-analytics="modal footer">
        <ng-content select="[buttons]"></ng-content>
      </div>
    </div>
  </div>

  <!-- bottom spacer -->
  <div class="pc-overlay__bottom-spacer"></div>
</div>
