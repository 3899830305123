import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function smallCharacterValidator(): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors | null => {
    const urlRegEx: RegExp = '^(?=.*[a-z])' as any
    if (control.value && !control.value.match(urlRegEx)) {
      return {
        lowerCaseError: 'The password must contain at least 1 lowercase letter.',
      }
    } else {
      return null
    }
  }
}

export function upperCharacterValidator(): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors | null => {
    const urlRegEx: RegExp = '(?=.*[A-Z])' as any
    if (control.value && !control.value.match(urlRegEx)) {
      return {
        upperCaseError: 'The password must contain at least 1 uppercase letter.',
      }
    } else {
      return null
    }
  }
}

export function numberValidator(): ValidatorFn {
  return (control: UntypedFormControl) => {
    const urlRegEx: RegExp = '(?=.*\\d)' as any
    if (control.value && !control.value.match(urlRegEx)) {
      return {
        numberError: 'The password must contain at least 1 number.',
      }
    } else {
      return null
    }
  }
}

export function specialCharacterValidator(): ValidatorFn {
  return (control: UntypedFormControl) => {
    const urlRegEx: RegExp = '(?=.*[@$!%*?&\\-+])' as any
    if (control.value && !control.value.match(urlRegEx)) {
      return {
        specialCharacterError: 'The password must contain at least 1 special character (@$!%*?&).',
      }
    } else {
      return null
    }
  }
}

export function passwordConfirmationValidator(formControl: AbstractControl, passwordFieldKey: string, confirmPasswordFieldKey: string): ValidatorFn {
  return (): ValidationErrors | null => {
    const password = formControl?.get(passwordFieldKey)?.value || ''
    const passwordConfirmation = formControl?.get(confirmPasswordFieldKey)?.value || ''
    if (password !== passwordConfirmation) {
      return {
        passwordNotMatchingError: 'The confirmation must match the password.',
      }
    } else {
      return null
    }
  }
}

export function matchEmailValidator(email: string): ValidatorFn {
  return (control: UntypedFormControl) => {
    if (control.value && control.value !== email) {
      return {
        mismatchError: 'The emails must correspond.',
      }
    } else {
      return null
    }
  }
}

export function ibanValidator(): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors | null => {
    const iban = control.value
    if (!iban) {
      return null
    }

    // two letters for the country code, followed by two digits, and then up to 30 alphanumeric characters
    const ibanPattern = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/
    const isValid = ibanPattern.test(iban)

    return isValid ? null : { invalidIban: true }
  }
}

export function bicValidator(): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors | null => {
    const bic = control.value
    if (!bic) {
      return null
    }

    // Regular expression to validate BIC
    const bicPattern = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/
    const isValid = bicPattern.test(bic)

    return isValid ? null : { invalidBic: true }
  }
}
