import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { IamService } from '@services/iam.service'
import { SSOSessionData } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { KeycloakService } from 'keycloak-angular'
import { Subject, Subscription } from 'rxjs'
import { take } from 'rxjs/operators'
import { AppService } from 'src/app/app.service'

@Component({
  selector: 'app-auth-callback',
  template: `
    <flex-col-center-center style="width: 100vw; height: 100vh; background: white;">
      <div class="lds-dual-ring" style="width: 100px; height: 100px;"></div>
    </flex-col-center-center>

    @if (showDriverRedirectOverlay$ | async) {
      <pc-overlay
        [config]="{
          title: 'Redirect to charge-repay.io',
          closebale: false
        }">
        <div body style="width: 100%">The charge-repay driver app has moved to a new location. Do you want to go there?</div>
        <flex-row-end buttons>
          <pc-button variant="secondary" [text]="'CHARGE_REPAY.STAY' | translate" (btnClick)="stayOnProficloud()"></pc-button>
          <pc-button [text]="'CHARGE_REPAY.GOTO' | translate" (btnClick)="goToChargeRepay()"></pc-button>
        </flex-row-end>
      </pc-overlay>
    }
  `,
})
export class AuthCallbackComponent implements OnInit, OnDestroy {
  constructor(
    public proficloud: ProficloudService,
    private iam: IamService,
    public router: Router,
    public app: AppService,
    private auth: KeycloakService
  ) {}

  showDriverRedirectOverlay$ = new Subject<boolean>()

  tokenReceivedSubscription: Subscription

  ngOnInit(): void {
    /*
    Check token validity coming from identity provider.
    If token is valid initialize login, otherwise redirect to start page
     */

    if (this.auth.isLoggedIn()) {
      console.log('Basically you are good to go!')
      this.initLogin()
    } else {
      console.log('Login failed.')
      this.router.navigate(['/'])
    }
  }

  private initLogin() {
    const sessionData: SSOSessionData = {
      access_token: localStorage.getItem('access_token'),
      refresh_token: localStorage.getItem('refresh_token'),
    }
    this.handleLogin(sessionData)
  }

  private handleLogin(sessionData: SSOSessionData) {
    // Set the session data the good old way
    this.proficloud.decodeSingleSignOnSession(sessionData)
    // Activate token refresh listener
    this.proficloud.listenForTokenRefresh()

    this.iam.refreshUserDetails()

    this.proficloud.userDataFetched$.pipe(take(1)).subscribe((r) => {
      // check for acceptedInvitation
      const acceptedInvitationString = localStorage.getItem('acceptedInvitation')
      if (acceptedInvitationString) {
        const { organizationId, invitationId } = JSON.parse(acceptedInvitationString)
        this.iam.acceptInvitation(organizationId, invitationId)
        localStorage.removeItem('acceptedInvitation')
      } else {
        // check if driver
        const isDriver = this.proficloud.keycloakData.userDetails?.data?.attributes?.roles?.includes('driver')
        const isOnProficloud = this.app.application.name === 'proficloud'

        if (isDriver && isOnProficloud) {
          this.showDriverRedirectOverlay$.next(true)
        } else {
          // LAST CHECK: check if we have a requested path
          const requestedPath = window.localStorage.getItem('requestedPath')
          if (requestedPath && requestedPath !== '/forgot-password' && requestedPath !== '/register' && !requestedPath.includes('email')) {
            window.localStorage.removeItem('requestedPath')
            this.router.navigateByUrl(requestedPath)
          } else {
            window.localStorage.removeItem('requestedPath')
            this.router.navigate(['/'])
          }
        }
      }
    })
  }

  stayOnProficloud() {
    this.showDriverRedirectOverlay$.next(false)
    this.router.navigate(['/'])
  }

  goToChargeRepay() {
    this.showDriverRedirectOverlay$.next(false)
    const env = window.location.hostname === 'localhost' ? 'local' : this.app.environment.frontend
    const redirectTo = this.app.getPartnerChargeRepayUrl(env)
    window.location.href = redirectTo
  }

  ngOnDestroy(): void {
    if (this.tokenReceivedSubscription) {
      this.tokenReceivedSubscription.unsubscribe()
    }
  }
}

@Component({
  selector: 'app-registration-callback',
  template: `
    <flex-col-center-center style="width: 100vw; height: 100vh; background: white;">
      <div class="lds-dual-ring" style="width: 100px; height: 100px;"></div>
    </flex-col-center-center>
  `,
})
export class RegistrationCallbackComponent implements OnInit {
  constructor(
    public proficloud: ProficloudService,
    public router: Router
  ) {}

  ngOnInit(): void {
    const isDriverRegistration = window.location.href.includes('driver-registration-callback')
    if (isDriverRegistration) {
      this.router.navigate(['/'])
    } else {
      this.router.navigate(['/'])
    }
  }
}
